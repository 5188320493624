import styled from 'styled-components';

export const Container = styled.ul`
  background: ${({ theme }) => theme.main.background};
  margin: 0;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;

  @media (min-width: ${({ theme }) => theme.dimensions.desktop.content}) {
    overflow: hidden;
    background: ${({ theme }) => theme.color.accent_dark};
  }
`;

export const Item = styled.li`
  display: inline-block;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 400;
  color: ${({ theme }) => theme.color.primary_dark};
  padding: 0.8rem 1rem;
  margin: 0;

  ${({ active }) =>
    active &&
    `
    font-weight: 700;
  `};

  @media (min-width: ${({ theme }) => theme.dimensions.desktop.content}) {
    color: ${({ theme }) => theme.color.text_on_primary};
  }
`;
