import { useState, useEffect } from 'react';

export const useIsMobile = () => {
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    if (!window) return;

    const onWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    setWindowWidth(window.innerWidth);
    window.addEventListener('resize', onWindowResize);

    () => window.removeEventListener('resize', onWindowResize);
  }, []);

  return {
    isMobile: windowWidth < 767,
    isTablet: windowWidth > 766 && windowWidth < 1024,
    isDesktop: windowWidth > 1023,
  };
};
