import styled from 'styled-components';
import { Link } from 'gatsby';

export const Container = styled.ul`
  border-top: 2px solid ${({ theme }) => theme.color.primary};
  background-color: ${({ theme }) => theme.main.background};
  margin: 0;
  max-height: 290px;
  overflow: scroll;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    max-height: 420px;
    min-height: 420px;
    overflow-x: hidden;
    width: 100%;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.color.text_on_secundary};
    }

    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.color.accent_dark};
    }
  }
`;

export const ItemContainer = styled.li`
  background-color: ${({ theme }) => theme.main.background};
  border-left: 15px solid
    ${({ theme, even }) =>
      even ? theme.color.primary_dark : theme.color.accent_dark};
  border-bottom: 1px solid ${({ theme }) => theme.sponsors.background};
  margin: 0;
  min-width: 480px;
`;

export const Date = styled.div`
  padding: 1rem;
  background-color: ${({ theme }) => theme.directors.background};
  min-width: 75px;
  max-width: 75px;
`;

export const MonthDay = styled.div`
  color: ${({ theme }) => theme.color.accent_dark};
  font-weight: 700;
  border-bottom: 2px solid ${({ theme }) => theme.color.accent_dark};
  margin-bottom: 10px;

  h3 {
    font-size: 26px;
    margin-bottom: 5px;
  }

  span {
    margin-bottom: 5px;
    text-transform: uppercase;
  }
`;

export const WeekDay = styled.span`
  color: ${({ theme }) => theme.color.accent_dark};
  font-size: ${({ theme }) => theme.text.size.min};
  text-transform: uppercase;
`;

export const TeamImage = styled.img`
  width: 65px;
`;

export const MatchOver = styled.div`
  background-color: ${({ theme }) => theme.directors.background};
  color: ${({ theme }) => theme.color.accent_dark};
  padding: 0.3rem 0.7rem;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 11px;
`;

export const Score = styled.h3`
  color: ${({ theme, highlight }) =>
    highlight ? theme.color.primary_dark : theme.color.accent_dark};
  font-size: 36px;
  font-weight: 700;
  margin: 0;
`;

export const ScoreSeparator = styled.span`
  color: ${({ theme }) => theme.color.text_on_secundary};
  font-size: 22px;
  margin: 0 1rem;
`;

export const ChampionshipName = styled.h4`
  margin: 0;
  color: ${({ theme }) => theme.color.black};
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 5px;

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.med}) {
    font-size: ${({ theme }) => theme.text.size.min};
  }
`;

export const MatchLocation = styled.h5`
  font-size: 12px;
  color: #7b7b7a;
  margin: 0;
  font-weight: 400;

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.med}) {
    font-size: ${({ theme }) => theme.text.size.min};
  }
`;

export const MatchReview = styled(Link)`
  align-items: center;
  font-size: ${({ theme }) => theme.text.calcGenerically(12, 16)};
  font-weight: 700;
  line-height: 1.5em;
  border-radius: 2px;
  margin: 10px;
  padding: 0.3rem 0.5rem;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  color: white;
  background-color: ${({ theme }) => theme.colors.primary_dark};
`;
