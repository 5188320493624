import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import fetch from 'node-fetch';
import moment from 'moment';
import { decodeHtml } from '../../helpers';

import logoYoutubube from '../../images/logo-canal-youtube.png';
import iconYoutube from '../../images/icon-youtube.svg';

import {
  Container,
  Header,
  HeaderContent,
  Logo,
  Content,
  ContentLeft,
  WrapperVideo,
  TitleVideo,
  DateVideo,
  ContentRight,
  ListVideos,
  SelectVideo,
  VideoThumb,
  VideoDesc,
  Button,
} from './styles';

const youtubeEmbedPath = 'https://www.youtube.com/embed';
const channelLink = 'https://www.youtube.com/c/scinternacional';

const parserVideo = ({ date, ...rest }) => {
  const parseDate = (data) => {
    const dateMoment = moment(data);
    const comparison = moment(new Date()).diff(data, 'days');

    return `${dateMoment.format('DD/MM/YYYY')}, há ${
      comparison < 2 ? `${comparison} dia` : `${comparison} dias`
    }`;
  };

  return {
    ...rest,
    date: parseDate(date),
  };
};

function Youtube() {
  const [videos, setVideos] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const {
    site: {
      siteMetadata: { youtubeUrlSearch },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            youtubeUrlSearch
          }
        }
      }
    `,
  );

  const getVideos = async () => {
    try {
      const response = await fetch(youtubeUrlSearch);
      const data = await response.json();

      setVideos(data.map(parserVideo));
    } catch (err) {
      setVideos([]);
    }
  };

  useEffect(() => {
    getVideos();
  }, []);

  const videoSelected = videos[selectedIndex];

  return (
    <Container>
      <Header>
        <HeaderContent>
          <Logo src={logoYoutubube} alt="Logo do inter" />
        </HeaderContent>
      </Header>

      <Content>
        {videoSelected && (
          <ContentLeft>
            <WrapperVideo>
              <iframe
                title="Video player"
                src={`${youtubeEmbedPath}/${videoSelected.id}`}
                width="100%"
                height="375"
                frameBorder="0"
              />
            </WrapperVideo>

            <TitleVideo>{decodeHtml(videoSelected.title)}</TitleVideo>
            <DateVideo>{videoSelected.date}</DateVideo>
          </ContentLeft>
        )}

        <ContentRight>
          <ListVideos>
            {videos.map((video, index) => (
              <SelectVideo
                key={video.id}
                onClick={() => setSelectedIndex(index)}
              >
                <VideoThumb src={video.thumbnail} />
                <VideoDesc>
                  <TitleVideo>{video.title}</TitleVideo>
                  <DateVideo>{video.date}</DateVideo>
                </VideoDesc>
              </SelectVideo>
            ))}
          </ListVideos>

          <Button href={channelLink} target="__blank">
            <strong>VER MAIS</strong>
            <img src={iconYoutube} alt="Ícone Youtube" />
          </Button>
        </ContentRight>
      </Content>
    </Container>
  );
}

export default Youtube;
