import styled from 'styled-components';
import soundcloudBanner from '../../images/soundcloud-banner.svg';

export const SoundItem = styled.div`
  width: 100%;
  height: 180px;
  padding: 10px;
  margin-bottom: 25px;

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.35);
    width: 1000px;
    margin: auto;
    margin-bottom: 0;
    padding: 0;
  }
`;

export const MainParagraph = styled.p`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 1rem;
  line-height: 1.5rem;
  @media (min-width: ${({ theme }) => theme.dimensions.mobile.med}) {
    line-height: 2.5rem;
  }
  justify-content: center;
  span:first-of-type {
    color: ${({ theme }) => theme.colors.highlight};
  }
  span:last-of-type {
    font-weight: 800;
    font-size: 1.5rem;
    text-transform: uppercase;
    font-family: montserrat;
    @media (min-width: ${({ theme }) => theme.dimensions.mobile.med}) {
      font-size: 2.5rem;
    }
  }
`;

export const SoundCloudSection = styled.div`
  width: 100vw;
  height: 26rem;
  background-image: url(${({ bgImg }) => bgImg || soundcloudBanner});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: ${({ theme }) => theme.colors.white};
`;

export const Image = styled.img`
  @media (max-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    width: 5rem;
  }
`;

export const Link = styled.a`
  max-width: 400px;
  width: 100%;
  display: block;
`;

export const SeeMore = styled.button`
  width: 100%;
  border-radius: 100px;
  background-color: ${({ theme }) => theme.color.primary};
  display: flex;
  padding: 10px 10px 10px 40px;
  justify-content: space-between;
  align-items: center;
  border: none;
  cursor: pointer;
  transition: 250ms ease-in-out;
  span {
    font-weight: 700;
    color: ${({ theme }) => theme.color.text_on_primary};
  }
  img {
    width: 40px;
  }
  &:hover {
    background-color: ${({ theme }) => theme.color.primary_dark};
  }
  @media (min-width: ${({ theme }) => theme.dimensions.mobile.med}) {
    width: 400px;
  }
`;
