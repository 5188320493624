import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Swiper from 'react-id-swiper';
import { ImageCard, Thumb, Title, Tag } from './cards';
import { decodeHtml, convertPositionNounGender } from '../helpers';
import ConditionalRenderer from './conditionalRenderer';

const Card = styled(ImageCard)`
  max-width: 320px;
  min-height: 400px;
`;

const Container = styled.div`
  width: 100%;
  padding: 1rem;

  .swiper-button-next,
  .swiper-button-prev {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const Shirt = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.color.primary};
  color: ${(props) => props.theme.color.text_on_primary};
  font-weight: bold;
  font-size: 0.9rem;
  z-index: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function PlayersCarousel(props) {
  const { players, team } = props;

  const params = {
    effect: 'coverflow',
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 'auto',
    rebuildOnUpdate: true,
    updateOnWindowResize: true,
    loop: true,
    coverflowEffect: {
      rotate: 0,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };

  return (
    <Container>
      <Swiper {...params}>
        {players.map((player, index) => (
          <Card to={`/futebol/${player.slug}`} key={index}>
            <ConditionalRenderer
              condition={player.playerData.numeroDaCamiseta}
              renderOnSuccess={() => (
                <Shirt>{player.playerData.numeroDaCamiseta}</Shirt>
              )}
            />
            <Thumb src={player.playerData.foto.sourceUrl} />
            <Title>{decodeHtml(player.title)}</Title>
            <Tag>
              {convertPositionNounGender(
                decodeHtml(player.position.label),
                team,
              )}
            </Tag>
          </Card>
        ))}
      </Swiper>
    </Container>
  );
}

PlayersCarousel.propTypes = {
  players: PropTypes.arrayOf(PropTypes.any),
};
