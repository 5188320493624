import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

export default function withSponsoredBanners(Component) {
  const query = graphql`
    query GET_SPONSORED_BANNERS {
      wpgraphql {
        generalConfig(id: "sponsored_banners", idType: SLUG) {
          sponsoredBanners {
            banners {
              image {
                sourceUrl
              }
              title
              linkType
              link
              to
            }
          }
        }
      }
    }
  `;
  const render = (data) => {
    const {
      wpgraphql: {
        generalConfig: { sponsoredBanners },
      },
    } = data;
    const { banners } = sponsoredBanners;

    return <Component banners={banners} />;
  };

  return function HOC() {
    return <StaticQuery query={query} render={render} />;
  };
}
