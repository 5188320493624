import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { convertPositionNounGender } from '../helpers';

export default function withAllPlayers(Component) {
  const query = graphql`
    query {
      wpgraphql {
        teamFutebolFeminino(first: 100) {
          nodes {
            id
            title
            slug
            position {
              label
              value
            }
            role {
              label
              value
            }
            order
            playerData {
              nome
              numeroDaCamiseta
              foto {
                sourceUrl
              }
            }
          }
        }
        teamFutebolMasculino(first: 100) {
          nodes {
            id
            title
            slug
            position {
              label
              value
            }
            role {
              label
              value
            }
            order
            playerData {
              nome
              numeroDaCamiseta
              foto {
                sourceUrl
              }
            }
          }
        }
        teamJuniores(first: 100) {
          nodes {
            id
            title
            slug
            position {
              label
              value
            }
            role {
              label
              value
            }
            order
            playerData {
              nome
              numeroDaCamiseta
              foto {
                sourceUrl
              }
            }
          }
        }
        teamJuvenil(first: 100) {
          nodes {
            id
            title
            slug
            position {
              label
              value
            }
            role {
              label
              value
            }
            order
            playerData {
              nome
              numeroDaCamiseta
              foto {
                sourceUrl
              }
            }
          }
        }
      }
    }
  `;

  function render(data) {
    const {
      wpgraphql: {
        teamFutebolFeminino,
        teamFutebolMasculino,
        teamJuniores,
        teamJuvenil,
      },
    } = data;
    const players = {
      'Futebol Feminino': teamFutebolFeminino.nodes,
      'Futebol Masculino': teamFutebolMasculino.nodes,
      Juniores: teamJuniores.nodes,
      Juvenil: teamJuvenil.nodes,
    };

    return <Component players={players} />;
  }

  return function HOC() {
    return <StaticQuery query={query} render={render} />;
  };
}
