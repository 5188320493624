import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
  getChampionshipMatches,
  fetchCalendar,
  fetchChampionships,
  fetchTable,
  getTeamClassifications,
} from '../../helpers/matches';
import withRadio from '../../data/radio';
import FlexDiv from '../flex-div';
import Selector from './selector';
import Classifications from './classifications';
import Matches from './matches';

const MatchesWidget = ({ radioLink }) => {
  const [championships, setChampionships] = useState([]);
  const [activeChampionship, setActiveChampionship] = useState(null);
  const [matches, setMatches] = useState(null);
  const [classifications, setClassifications] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const {
    site: {
      siteMetadata: { groundAPI, teamId },
    },
    wpgraphql: {
      matches: { nodes: featuredMatches },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            groundAPI
            teamId
          }
        }
        wpgraphql {
          matches(
            first: 200
            where: { parent: null, orderby: { field: DATE, order: DESC } }
          ) {
            nodes {
              servicoDeJogo {
                matchId
                review {
                  ... on WPGraphQL_Post {
                    id
                    slug
                  }
                }
                finished
              }
            }
          }
        }
      }
    `,
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [calendar, championShips] = await Promise.all([
          fetchCalendar(groundAPI),
          fetchChampionships(groundAPI),
        ]);
        const matchesByChampionship = getChampionshipMatches(calendar);
        const tables = await Promise.all(
          championShips
            .filter(({ classificacao }) => classificacao === '1')
            .map(({ id }) => fetchTable(groundAPI, id)),
        );
        setClassifications(getTeamClassifications(tables, teamId));

        // Get next championship match
        const { id: championshipNextMatchId } = calendar.reduceRight(
          (acc, singleMatch) => {
            const splittedDate = singleMatch.date.split('/');
            const formattedDate = new Date(
              `${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`,
            );
            const isTodayOrAfter = formattedDate >= new Date();
            if (isTodayOrAfter && !acc.date && acc.date < formattedDate) {
              acc.date = singleMatch.date;
              acc.id = singleMatch.championshipId;
            }
            return acc;
          },
          { id: championShips[0].id, date: null },
        );
        setChampionships(championShips);
        setMatches(matchesByChampionship);
        setActiveChampionship(championshipNextMatchId);
      } catch (err) {
        setError(true);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  if (error || loading) return null;

  return (
    <div>
      <Selector
        championships={championships}
        activeId={activeChampionship}
        onChange={setActiveChampionship}
      />
      <FlexDiv flexDirection={['column', 'column', 'column', 'row']}>
        <Matches
          matches={matches[activeChampionship]}
          featuredMatches={featuredMatches}
        />
        <Classifications
          table={classifications[activeChampionship]}
          radioLink={radioLink}
        />
      </FlexDiv>
    </div>
  );
};

export default withRadio(MatchesWidget);
