import React, { useState } from 'react';
import styled from 'styled-components';
import withAllPlayers from '../data/players';
import PlayersCarousel from './players-carousel';

const Container = styled.div`
  width: 100%;
`;

const Header = styled.div`
  height: ${({ theme }) => theme.text.calcGenerically(160, 288)};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    padding-top: 5rem;
  }

  h2 {
    font-size: ${({ theme }) => theme.text.calcGenerically(80, 240)};
    color: rgba(255, 255, 255, 0.05);
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }

  ul {
    z-index: 1;
  }
`;

const Tabs = styled.ul`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin: 0;

  li {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 160px;
    margin: 0;

    button {
      font-size: 1.2rem;
      line-height: 2em;
      text-transform: uppercase;
      border: none;
      background: none;
      cursor: pointer;
      color: ${({ theme }) => theme.colors.white};
      width: 100%;

      &.selected {
        font-weight: 700;
      }
    }
  }
`;

export function PlayersWidget(props) {
  const { players } = props;
  const [group, setGroup] = useState('Futebol Masculino');

  return (
    <Container>
      <Header>
        <h2>Elenco</h2>
        <Tabs>
          <li>
            <button
              type="button"
              onClick={() => setGroup('Futebol Masculino')}
              className={
                group === 'Futebol Masculino' ? 'selected' : 'unselected'
              }
            >
              Masculino
            </button>
          </li>
          <li>
            <button
              type="button"
              onClick={() => setGroup('Futebol Feminino')}
              className={
                group === 'Futebol Feminino' ? 'selected' : 'unselected'
              }
            >
              Feminino
            </button>
          </li>
          <li>
            <button
              type="button"
              onClick={() => setGroup('Juniores')}
              className={group === 'Juniores' ? 'selected' : 'unselected'}
            >
              Júnior
            </button>
          </li>
          <li>
            <button
              type="button"
              onClick={() => setGroup('Juvenil')}
              className={group === 'Juvenil' ? 'selected' : 'unselected'}
            >
              Juvenil
            </button>
          </li>
        </Tabs>
      </Header>
      <PlayersCarousel players={players[group]} team={group} />
    </Container>
  );
}

export default withAllPlayers(PlayersWidget);
