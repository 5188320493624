import React from 'react';
import * as Styles from './styles';

const Selector = ({ championships, activeId, onChange }) => (
  <Styles.Container>
    {championships.map(({ id, name }) => (
      <Styles.Item
        onClick={() => onChange(id)}
        key={id}
        active={activeId === id}
      >
        {name}
      </Styles.Item>
    ))}
  </Styles.Container>
);

export default Selector;
