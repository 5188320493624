import styled, { css } from 'styled-components';
// import twitterBackground from '../../images/twitter-background.png';
import twitterBackground from '../../images/placeholderNews2x.png';

const TextCommom = css`
  color: ${({ theme }) => theme.color.text_on_primary};
  position: absolute;
  bottom: 0;
  transition: 250ms ease-in-out;
  left: 0;
  padding: 1rem;
  margin: 0;
  font-size: ${({ theme }) => theme.text.size.default}px;

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    font-size: ${({ theme }) => theme.text.size.h3.min}px;
  }
`;

export const Preview = styled.p`
  ${TextCommom};
`;

export const FullText = styled.p`
  ${TextCommom};
  opacity: 0;
  z-index: 1;
  visibility: hidden;
`;

export const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  min-height: 370px;
  height: 370px;
  max-width: 80%;
  background-image: url(${({ image }) => image || twitterBackground});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 1rem;
  border-radius: 5px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 6.79%,
      rgba(0, 0, 0, 0.85) 98.64%,
      rgba(0, 0, 0, 0.85) 98.64%
    );
    height: 50%;
    transition: 250ms ease-in-out;
    width: 100%;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    background: linear-gradient(
      180deg,
      rgba(182, 27, 24, 0.8),
      rgba(0, 0, 0, 0.8)
    );
    backdrop-filter: blur(5px) brightness(80%);
    height: 100%;
    width: 100%;
    transition: 250ms ease-in-out;
    opacity: 0;
    visibility: hidden;
  }

  &:hover {
    ${Preview} {
      display: none;
    }

    ${FullText} {
      opacity: 1;
      visibility: visible;
    }

    &:before {
      display: none;
    }

    &:after {
      opacity: 1;
      visibility: visible;
    }
  }

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    max-width: 370px;
    min-width: 370px;
  }
`;

export const Tag = styled.div`
  font-weight: 700;
  font-size: 10px;
  z-index: 1;
  position: relative;
  color: ${({ theme }) => theme.color.text_on_primary};
  background-color: ${({ theme }) => theme.color.primary};
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  display: inline-block;
  padding: 5px 10px;
`;

export const TwitterSection = styled.div`
  padding: 40px 0;

  .swiper-button-next {
    right: 0;
  }
  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next,
  .swiper-button-prev {
    height: 100px;
    width: 100px;
    font-weight: 700;
    transform: translateY(-50%);
    margin-top: 0;
    background: rgba(0, 0, 0, 0.6);
    padding: 2rem;
    color: ${({ theme }) => theme.colors.white};
  }

  @media (max-width: ${(props) => props.theme.dimensions.mobile.med}) {
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
  }
`;

export const Link = styled.a`
  max-width: 400px;
  width: 100%;
  display: block;
`;

export const SeeMore = styled.button`
  width: 100%;
  border-radius: 100px;
  background-color: ${({ theme }) => theme.color.primary};
  display: flex;
  padding: 10px 10px 10px 40px;
  justify-content: space-between;
  align-items: center;
  border: none;
  cursor: pointer;
  transition: 250ms ease-in-out;

  span {
    font-weight: 700;
    color: ${({ theme }) => theme.color.text_on_primary};
  }

  img {
    width: 40px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.primary_dark};
  }

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.med}) {
    width: 400px;
  }
`;
