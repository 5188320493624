import React, { useEffect, useState } from 'react';
import Swiper from 'react-id-swiper';
import { useStaticQuery, graphql } from 'gatsby';
import fetch from 'node-fetch';
import FlexDiv from '../flex-div';
import Container from '../container';
import * as Styles from './styles';

import twitterLogo from '../../images/twitter-logo.png';

function Twitter() {
  const [timeline, setTimeline] = useState(null);

  const params = {
    slidesPerView: 'auto',
    spaceBetween: 20,
    grabCursor: true,
    loop: true,
    rebuildOnUpdate: true,
    updateOnWindowResize: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      568: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 4,
      },
    },
  };

  const {
    site: {
      siteMetadata: { twitterEndpoint },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            twitterEndpoint
          }
        }
      }
    `,
  );

  const getTimeline = async () => {
    try {
      const response = await fetch(twitterEndpoint);
      const data = await response.json();

      setTimeline(data);
    } catch (err) {
      setTimeline(null);
    }
  };

  useEffect(() => {
    getTimeline();
  }, []);

  if (!timeline) return null;

  return (
    <Styles.TwitterSection>
      <Container>
        <Swiper {...params} shouldSwiperUpdate>
          {timeline.map((item) => (
            <Styles.Container
              key={item.id}
              image={item.images.length > 0 ? item.images[0].url : null}
            >
              <Styles.Tag>SCInternacional</Styles.Tag>
              <Styles.Preview>
                {item.text.length > 80
                  ? `${item.text.slice(0, 80)}...`
                  : item.text}
              </Styles.Preview>
              <Styles.FullText>{item.text}</Styles.FullText>
            </Styles.Container>
          ))}
        </Swiper>
        <FlexDiv justifyContent="center" marginTop="50px">
          <Styles.Link
            href="https://twitter.com/SCInternacional"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Styles.SeeMore>
              <span>VER MAIS</span>
              <img src={twitterLogo} alt="twitter" />
            </Styles.SeeMore>
          </Styles.Link>
        </FlexDiv>
      </Container>
    </Styles.TwitterSection>
  );
}

export default Twitter;
