import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.matchesWidget.background};
  display: flex;
  flex-direction: column;
  justify-content: center;

  > div {
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    width: 100%;
    max-width: 550px;
  }
`;

export const RadioLogo = styled.img`
  width: 135px;
  object-fit: contain;

  @media (min-width: 767px) {
    width: 170px;
  }
`;

export const ClassificationInfo = styled.div`
  background-color: ${({ theme }) => theme.directors.background};

  h3,
  h4,
  h5 {
    margin: 0;
    color: ${({ theme }) => theme.color.accent_dark};
  }

  h3 {
    min-width: 25px;
    text-align: right;
    margin-right: 10px;
  }

  span {
    font-size: ${({ theme }) => theme.text.size.default};
  }

  h4 {
    font-size: 44px;
    margin-bottom: 10px;
  }

  h5 {
    font-weight: 400;
    font-size: ${({ theme }) => theme.text.size.default};
  }

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.med}) {
    h4 {
      font-size: 50px;
    }
  }
`;

export const ClassificationHeader = styled.div`
  background-color: ${({ bg }) => bg};
  color: ${({ theme }) => theme.color.text_on_primary};
  font-size: ${({ theme }) => theme.text.size.min};

  img {
    width: 25px;
    margin: 0 5px;
  }

  p {
    margin: 0;
  }
  span {
    width: 25px;
  }

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.med}) {
    font-size: ${({ theme }) => theme.text.size.default};

    span {
      width: 40px;
    }
  }
`;

export const RadioContainer = styled.div`
  position: relative;
  width: 100%;

  &:before {
    content: '';
    background: ${({ theme }) => theme.matchesWidget.bottomDetail};
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 25%;
    display: block;
  }

  article {
    flex-basis: initial;
    flex-grow: initial;
  }
`;
