/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Swiper from 'react-id-swiper';
import ConditionalRenderer from './conditionalRenderer';

const Container = styled.div`
  width: 100%;

  ul {
    margin: 0;
  }

  li {
    margin: 0 1rem;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .swiper-button-next {
    right: 0;
  }
  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next,
  .swiper-button-prev {
    height: 100px;
    width: 100px;
    font-weight: 700;
    transform: translateY(-50%);
    margin-top: 0;
    background: rgba(0, 0, 0, 0.6);
    padding: 2rem;
    color: ${({ theme }) => theme.colors.white};
  }

  .swiper-button-disabled {
    display: none;
  }

  @media (max-width: ${(props) => props.theme.dimensions.mobile.med}) {
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
  }
`;

const Wrapper = styled.ul`
  width: 100%;
  display: flex;
`;

const Item = styled.li`
  max-width: 300px;
`;

const Banner = styled.figure`
  width: 300px;
  height: 200px;
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 100%;
    transition: transform 0.7s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
  }

  figcaption {
    padding: 0.2rem 0.5rem;
    width: 100%;
    height: 3em;
    box-sizing: border-box;
    font-size: 1rem;
    font-family: 'Bebas Neue';
    line-height: 1.2em;
    color: ${({ theme }) => theme.colors.white};
    position: absolute;
    bottom: 0;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.6);
  }
`;

function renderBanner(banner) {
  const { image, title, linkType, link, to } = banner;

  if (linkType === 'external') {
    return (
      <a
        href={link}
        alt="Banner de destaque"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Banner>
          <img src={image.sourceUrl} alt="" />
          <ConditionalRenderer
            condition={title}
            renderOnSuccess={() => <figcaption>{title}</figcaption>}
          />
        </Banner>
      </a>
    );
  }
  if (linkType === 'internal') {
    return (
      <Link to={to}>
        <Banner>
          <img src={image.sourceUrl} alt="" />
          <ConditionalRenderer
            condition={title}
            renderOnSuccess={() => <figcaption>{title}</figcaption>}
          />
        </Banner>
      </Link>
    );
  }
  return (
    <Banner>
      <img src={image.sourceUrl} alt="" />
      <ConditionalRenderer
        condition={title}
        renderOnSuccess={() => <figcaption>{title}</figcaption>}
      />
    </Banner>
  );
}

export default function FixedBanners(props) {
  const { banners } = props;

  const params = {
    slidesPerView: 'auto',
    loop: false,
    grabCursor: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };

  if (!banners) {
    return <div />;
  }

  return (
    <Container>
      <Wrapper>
        <Swiper {...params}>
          {banners.map((item, index) => (
            <Item key={index}>{renderBanner(item)}</Item>
          ))}
        </Swiper>
      </Wrapper>
    </Container>
  );
}
