import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

export default function withBanners(Component) {
  const query = graphql`
    query GET_BANNER {
      wpgraphql {
        generalConfig(id: "hero_banner", idType: SLUG) {
          bannerData {
            banners {
              bannerDesktop {
                sourceUrl
              }
              bannerMobile {
                sourceUrl
              }
              linkType
              link
              to
            }
          }
        }
      }
    }
  `;
  const render = (data) => {
    const {
      wpgraphql: {
        generalConfig: { bannerData },
      },
    } = data;
    const { banners } = bannerData;

    return <Component banners={banners} />;
  };

  return function HOC() {
    return <StaticQuery query={query} render={render} />;
  };
}
