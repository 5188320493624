import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

export default function withFixedBanners(Component) {
  const query = graphql`
    query GET_FIXED_BANNERS {
      wpgraphql {
        generalConfig(id: "fixed_banners", idType: SLUG) {
          fixedBanners {
            banners {
              image {
                sourceUrl
              }
              title
              linkType
              link
              to
            }
          }
        }
      }
    }
  `;
  const render = (data) => {
    const {
      wpgraphql: {
        generalConfig: { fixedBanners },
      },
    } = data;
    const { banners } = fixedBanners;

    return <Component banners={banners} />;
  };

  return function HOC() {
    return <StaticQuery query={query} render={render} />;
  };
}
