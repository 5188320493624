/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Swiper from 'react-id-swiper';
import theme from '../theme';

const CarouselWrapper = styled.section`
  .swiper-button-next,
  .swiper-button-prev {
    font-weight: 700;
    color: white;
  }

  @media (max-width: ${({ theme }) => theme.dimensions.mobile.med}) {
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
  }
`;

function Carousel(props) {
  const { children } = props;

  const params = {
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };

  return (
    <CarouselWrapper>
      <Swiper {...params}>{children}</Swiper>
    </CarouselWrapper>
  );
}

const Banner = styled.figure`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto;
  max-width: ${({ theme }) => theme.dimensions.mobile.max};
  max-height: 820px;
  background-color: ${({ theme }) => theme.colors.light_gray};
  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    max-width: 1920px;
    max-height: 1080px;
    margin: 0 auto;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
  }

  a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
  }

  &::after {
    content: '';
  }
`;

function renderBanner(banner, environment, key) {
  const { bannerDesktop, bannerMobile, linkType, link, to } = banner;
  let image = bannerDesktop.sourceUrl;

  if (environment === 'mobile' && bannerMobile) {
    image = bannerMobile.sourceUrl;
  }
  if (linkType === 'external') {
    return (
      <Banner key={key}>
        <img src={image} alt="" />
        {link.trim().length > 0 && (
          <a
            href={link}
            alt="Banner de destaque"
            target="_blank"
            rel="noopener noreferrer"
          />
        )}
      </Banner>
    );
  }
  if (linkType === 'internal') {
    return (
      <Banner key={key}>
        <img src={image} alt="" />
        <Link to={`/${to}`} />
      </Banner>
    );
  }
  return (
    <Banner key={key}>
      <img src={image} alt="" />
    </Banner>
  );
}

export default function HeroBanner(props) {
  const { banners } = props;
  const [environment, setEnv] = useState('desktop');

  function handleResize() {
    if (
      typeof window !== undefined &&
      window.innerWidth < theme.dimensions.mobile.max.slice(0, -2)
    ) {
      return setEnv('mobile');
    }
    return setEnv('desktop');
  }

  useEffect(() => {
    handleResize();

    if (typeof window !== undefined) {
      window.addEventListener('resize', handleResize);
    }
    return () => {
      if (typeof window !== undefined) {
        window.removeEventListener('resize', handleResize);
      }
    };
  });

  if (banners.length > 1) {
    return (
      <Carousel>
        {banners.map((banner, index) =>
          renderBanner(banner, environment, index),
        )}
      </Carousel>
    );
  }
  const [banner] = banners;

  return renderBanner(banner, environment, 1);
}

HeroBanner.propTypes = {
  banners: PropTypes.arrayOf(PropTypes.any).isRequired,
  games: PropTypes.arrayOf(PropTypes.any),
};
