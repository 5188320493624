import React from 'react';
import RadioPlayer from '../../Radio';
import FlexDiv from '../../flex-div';
import theme from '../../../theme';
import RadioImage from '../../../images/Logo_Radio_1.png';
import * as Styles from './styles';
import ConditionalRenderer from '../../conditionalRenderer';

const Classifications = ({ table, radioLink }) => (
  <ConditionalRenderer
    condition={table}
    renderOnSuccess={() => (
      <Styles.Container>
        <>
          <Styles.ClassificationHeader bg={theme.color.accent_dark}>
            <FlexDiv justifyContent="space-between" padding="1rem">
              <p>{table.group ? `Grupo ${table.group}` : 'Classificação'}</p>
              <FlexDiv>
                <span>PG</span>
                <span>J</span>
                <span>V</span>
                <span>E</span>
                <span>D</span>
                <span>SG</span>
              </FlexDiv>
            </FlexDiv>
          </Styles.ClassificationHeader>
          <Styles.ClassificationHeader bg={theme.color.primary_dark}>
            <FlexDiv
              alignItems="center"
              justifyContent="space-between"
              padding="1rem"
            >
              <FlexDiv alignItems="center">
                {table.position} <img src={table.teamImage} alt={table.name} />
                <p>{table.name}</p>
              </FlexDiv>
              <FlexDiv>
                <span>{table.points}</span>
                <span>{table.games}</span>
                <span>{table.wins}</span>
                <span>{table.draws}</span>
                <span>{table.loses}</span>
                <span>{table.positiveGols}</span>
              </FlexDiv>
            </FlexDiv>
          </Styles.ClassificationHeader>
          <Styles.ClassificationInfo>
            <FlexDiv
              padding={['3rem 1rem', '3rem 1rem', '3rem 2.5rem']}
              justifyContent="space-between"
            >
              <FlexDiv>
                <FlexDiv flexDirection="column">
                  <h4>{table.position}</h4>
                  <h5>POSIÇÃO</h5>
                </FlexDiv>
                <FlexDiv flexDirection="column" marginLeft="1.5rem">
                  <h4>{table.points}</h4>
                  <h5>PONTOS</h5>
                </FlexDiv>
              </FlexDiv>
              <FlexDiv flexDirection="column" justifyContent="center">
                <FlexDiv alignItems="center">
                  <h3>{table.wins}</h3>
                  <span>VITÓRIAS</span>
                </FlexDiv>
                <FlexDiv alignItems="center">
                  <h3>{table.draws}</h3>
                  <span>EMPATES</span>
                </FlexDiv>
                <FlexDiv alignItems="center">
                  <h3>{table.loses}</h3>
                  <span>DERROTAS</span>
                </FlexDiv>
              </FlexDiv>
            </FlexDiv>
          </Styles.ClassificationInfo>
        </>
        <Styles.RadioContainer>
          <FlexDiv
            padding={['1.5rem', '1.5rem', '2rem']}
            justifyContent="space-between"
          >
            <Styles.RadioLogo src={RadioImage} alt="Rádio Colorada" />
            <RadioPlayer
              src={radioLink}
              background="transparent"
              backgroundOnHover="transparent"
              sliderNextColor={theme.color.accent_dark}
              sliderPrevColor={theme.color.accent}
              sliderWidth="3rem"
              iconsSize="2rem"
              autoPlay={false}
            />
          </FlexDiv>
        </Styles.RadioContainer>
      </Styles.Container>
    )}
  />
);

export default Classifications;
