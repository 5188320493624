/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

import bannerYoutube from '../../images/banner-youtube.png';
import ContainerStyle from '../container';

const gray = '#9E9E9E';

export const Container = styled.div`
  background-color: #000;
  background-image: linear-gradient(
      179.74deg,
      rgba(0, 0, 0, 0.5) 0%,
      #000 83.37%
    ),
    url(${bannerYoutube});

  background-repeat: no-repeat;
  background-position: top right;

  padding: 0 0 50px;

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    background-image: none;
  }
`;

export const Header = styled.div`
  padding-top: 20px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    padding-top: 40px;
    padding-bottom: 50px;
    justify-content: flex-start;

    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.0001) 0%,
        #000 100%
      ),
      url(${bannerYoutube});
    background-repeat: no-repeat;
    background-position: top right;
  }
`;

export const HeaderContent = styled(ContainerStyle)`
  flex: 1;
`;

export const Logo = styled.img`
  width: 315px;

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    width: 460px;
  }
`;

export const Content = styled(ContainerStyle)`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    flex-direction: row;
  }
`;

export const TitleVideo = styled.strong`
  display: block;
  font-size: ${({ theme }) => theme.text.size.h1.min}px;
  color: ${({ theme }) => theme.colors.white};
`;

export const DateVideo = styled.span`
  color: ${gray};
  font-size: 20px;
`;

export const ContentLeft = styled.div`
  margin-bottom: 40px;

  ${TitleVideo} {
    margin: 20px 0 15px;
    font-size: 22px;
  }

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    margin-bottom: 0;
    margin-right: 110px;
    width: 50%;

    ${TitleVideo} {
      margin-bottom: 30px;
      font-size: 30px;
    }
  }
`;

export const WrapperVideo = styled.div`
  width: 100%;
  padding-bottom: 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
`;

export const ContentRight = styled.div`
  ${TitleVideo} {
    margin-bottom: 20px;
  }
`;

const thumbHeight = 95;
const videosMarginDesktop = 60;
const videosMarginMobile = 20;

export const ListVideos = styled.div`
  height: 325px;
  overflow-y: auto;

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    height: 405px;
  }
`;

export const SelectVideo = styled.div`
  display: flex;
  height: ${thumbHeight}px;
  cursor: pointer;
  margin-bottom: ${videosMarginMobile}px;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    margin-bottom: ${videosMarginDesktop}px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const VideoThumb = styled.img`
  height: ${thumbHeight}px;
  width: 150px;
  margin-right: 10px;

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    width: 175px;
  }
`;

export const VideoDesc = styled.div`
  max-width: 210px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${TitleVideo} {
    font-size: 16px;
    margin-bottom: 0;
  }

  ${DateVideo} {
    font-size: 14px;
  }

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    max-width: 240px;
  }
`;

export const Button = styled.a`
  background: ${({ theme }) => theme.colors.primary};
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 40px;
  margin-top: 40px;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 100px;
  transition: opacity 0.2s;

  strong {
    font-size: 20px;
  }

  &:hover {
    opacity: 0.8;
  }
`;
