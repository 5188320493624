import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Swiper from 'react-id-swiper';
import ConditionalRenderer from './conditionalRenderer';
import Countdown from 'ground-web/components/v1/Countdown';
import VersusCard from 'ground-web/components/v1/VersusCard';
import { renderButtons } from '../helpers/matches';

const breakpoint = 1300;

const MainContainer = styled.div`
  flex-wrap: nowrap;
  flex-direction: column;
  @media (min-width: ${breakpoint}px) {
    flex-direction: row;
  }
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 300px;
  height: 100%;
  margin: 0;
  background-color: ${({ theme }) => theme.colors.light_gray};
  padding: 0 40px;
  box-sizing: border-box;
`;

const ContentContainer = styled.div`
  padding: 0 0.5em 0.5em 0.5em;
  display: flex;
  @media (max-width: 760px) {
    flex-wrap: wrap;
  }
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1;
  & > div:nth-of-type(2) {
    order: 2;
  }
  & > div:last-of-type {
    order: 1;
  }
  @media (min-width: 392px) {
    & > div:nth-of-type(2) {
      order: unset;
    }
    & > div:last-of-type {
      order: unset;
    }
  }
`;

const NormalInfoContainer = styled.div`
  margin: 0 1em 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 375px) {
    margin: 0;
  }
  @media (min-width: 392px) {
    align-items: flex-start;
  }
  @media (min-width: 760px) {
    margin: 0 1em;
  }
  @media (min-width: 1200px) {
    margin: 0 2em;
  }
  @media (min-width: 1400px) {
    margin: 0 1em;
  }
  @media (min-width: 1800px) {
    margin: 0 3em;
  }
`;

const CountdownContainer = styled.div`
  display: flex;
  @media (max-width: 760px) {
    width: 100%;
  }
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ChildrenContainer = styled.div`
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0;
  justify-content: center;
  margin: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  top: 100%;
  transition: all 0.5s;

  * {
    flex-grow: 1;
    flex-shrink: 0;
    margin: 0.5em;
  }

  @media (min-width: ${breakpoint}px) {
    flex-direction: row;
    flex-wrap: nowrap;
    position: initial;
    padding: 1em;
    justify-content: space-around;
    height: 100%;
    margin-right: auto;

    * {
      flex-grow: 0;
      flex-shrink: 1;
      margin: unset;
    }
  }
`;

const VersusCardContainer = styled.div`
  height: 4em;
  max-width: 100%;
`;

const Separator = styled.div`
  display: none;
  @media (min-width: ${breakpoint}px) {
    display: block;
  }
  width: 2px;
  height: 3em;
  background-color: #7b7b7a;
  margin: 0 1em;
`;

const ChampionshipText = styled.p`
  font-size: 0.8rem;
  font-weight: bold;
  margin: 0.1rem;
  @media (min-width: 425px) {
    font-size: 1rem;
  }
`;

const MatchInfoText = styled.p`
  font-size: 0.8rem;
  margin: 0.1rem;
  text-transform: capitalize;
`;

const DesktopCountdown = styled(Countdown)`
  display: none;
  @media (min-width: 760px) {
    display: flex;
  }
`;

const SwiperWrapper = styled.div`
  .swiper-button-next,
  .swiper-button-prev {
    color: ${({ theme }) => theme.colors.primary};
  }
  .swiper-wrapper {
    align-items: center;
  }
`;

const MatchBar = ({ matches }) => {
  const params = {
    slidesPerView: 1,
    loop: false,
    grabCursor: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };
  return (
    <SwiperWrapper>
      <Swiper {...params}>
        {matches?.map((match, index) => {
          const matchDate = match.dateUndefined
            ? 'A definir'
            : match.matchDate.locale('pt').format('dddd, DD/MM');

          const teams = {
            home: {
              shield: {
                source: match.homeImg,
                alt: match.homeTeamName,
              },
            },
            visitor: {
              shield: {
                source: match.visitorImg,
                alt: match.visitorTeamName,
              },
            },
          };
          return (
            <div key={index}>
              <MainContainer>
                <ContentContainer>
                  <ConditionalRenderer
                    condition={!match.dateUndefined && !match.timeUndefined}
                    renderOnSuccess={() => (
                      <CountdownContainer>
                        <DesktopCountdown
                          targetMilliseconds={match.matchDate.valueOf()}
                        />
                      </CountdownContainer>
                    )}
                  />

                  <NormalInfoContainer>
                    <ChampionshipText>{match.championship}</ChampionshipText>
                    <MatchInfoText>{matchDate}</MatchInfoText>
                    <MatchInfoText>{match.stadium}</MatchInfoText>
                  </NormalInfoContainer>
                  {/* <ReducedInfoContainer>
                    <ChampionshipText>
                      {match.championship}
                    </ChampionshipText>
                    <MatchInfoText>{matchInfo.reduced?.date}</MatchInfoText>
                    <MatchInfoText>{matchInfo.reduced?.local}</MatchInfoText>
                  </ReducedInfoContainer> */}
                  <VersusCardContainer>
                    <VersusCard
                      leftTeam={teams?.home}
                      rightTeam={teams?.visitor}
                    />
                  </VersusCardContainer>
                </ContentContainer>
                <Separator />
                <ChildrenContainer>
                  {renderButtons(match, true)}
                </ChildrenContainer>
              </MainContainer>
            </div>
          );
        })}
      </Swiper>
    </SwiperWrapper>
  );
};

export default MatchBar;

MatchBar.propTypes = {
  matches: PropTypes.arrayOf({
    nextGameText: PropTypes.string,
    matchInfo: PropTypes.shape({
      normal: PropTypes.shape({
        championship: PropTypes.string.isRequired,
        date: PropTypes.string,
        local: PropTypes.string.isRequired,
      }).isRequired,
      reduced: PropTypes.shape({
        championship: PropTypes.string.isRequired,
        date: PropTypes.string,
        local: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }),
};
