import React, { useEffect } from 'react';
import FlexDiv from '../../flex-div';
import ConditionalRenderer from '../../conditionalRenderer';
import * as Styles from './styles';
import { useIsMobile } from '../../../helpers/hooks';

const Matches = ({ matches, featuredMatches }) => {
  const { isDesktop } = useIsMobile();
  const data = matches.map((match) => {
    const featuredMatch = featuredMatches.find(
      (item) =>
        parseInt(item.servicoDeJogo.matchId, 10) === parseInt(match.gameId, 10),
    );

    if (featuredMatch) {
      const {
        servicoDeJogo: { review },
      } = featuredMatch;
      return { ...match, review };
    }
    return { ...match, review: null };
  });

  // useEffect(() => {
  //   const nextMatch = document.querySelector('#next-match');
  //   if (nextMatch) {
  //     nextMatch.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
  //   }
  // }, [matches]);

  return (
    <Styles.Container>
      {data.map((match, index) => (
        <Styles.ItemContainer
          id={match.nextMatch ? 'next-match' : `match_${match.id}`}
          key={match.id}
          even={index % 2 === 0}
        >
          <FlexDiv alignItems="stretch">
            <Styles.Date>
              <FlexDiv flexDirection="column" alignItems="center">
                <Styles.MonthDay>
                  <FlexDiv
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <h3>{match.day}</h3>
                    <span>{match.month}</span>
                  </FlexDiv>
                </Styles.MonthDay>
                <Styles.WeekDay>{match.weekdayText}</Styles.WeekDay>
              </FlexDiv>
            </Styles.Date>
            <FlexDiv padding="0.7rem 1.3rem" alignItems="center">
              <Styles.TeamImage src={match.homeImg} alt={match.homeTeamName} />
              <FlexDiv
                flexDirection="column"
                alignItems="center"
                width={['170px', '170px', '250px', 'auto']}
                padding="0 1rem"
              >
                <ConditionalRenderer
                  condition={!isDesktop && match.isFinished}
                  renderOnSuccess={() => (
                    <Styles.MatchOver>PARTIDA ENCERRADA</Styles.MatchOver>
                  )}
                />
                <FlexDiv
                  alignItems="center"
                  justifyContent="center"
                  marginBottom="0.5rem"
                >
                  <ConditionalRenderer
                    condition={match.isFinished}
                    renderOnSuccess={() => (
                      <Styles.Score highlight={match.homeTeam === 'INT'}>
                        {match.homeGols}
                      </Styles.Score>
                    )}
                  />
                  <Styles.ScoreSeparator>X</Styles.ScoreSeparator>
                  <ConditionalRenderer
                    condition={match.isFinished}
                    renderOnSuccess={() => (
                      <Styles.Score highlight={match.visitorTeam === 'INT'}>
                        {match.visitorGols}
                      </Styles.Score>
                    )}
                  />
                </FlexDiv>
                <ConditionalRenderer
                  condition={!isDesktop}
                  renderOnSuccess={() => (
                    <FlexDiv
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Styles.ChampionshipName>
                        {match.championship}
                      </Styles.ChampionshipName>
                      <Styles.MatchLocation>
                        {match.timeText} - Estádio {match.stadium}
                      </Styles.MatchLocation>
                    </FlexDiv>
                  )}
                />
                <ConditionalRenderer
                  condition={!isDesktop && match.review}
                  renderOnSuccess={() => (
                    <FlexDiv
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Styles.MatchReview to={`noticias/${match.review.slug}`}>
                        Veja como foi
                      </Styles.MatchReview>
                    </FlexDiv>
                  )}
                />
              </FlexDiv>
              <Styles.TeamImage
                src={match.visitorImg}
                alt={match.visitorTeamName}
              />
              <ConditionalRenderer
                condition={isDesktop}
                renderOnSuccess={() => (
                  <FlexDiv
                    flexDirection="column"
                    alignItems="flex-start"
                    marginLeft="1.5rem"
                  >
                    {match.isFinished && (
                      <Styles.MatchOver>PARTIDA ENCERRADA</Styles.MatchOver>
                    )}
                    <FlexDiv flexDirection="column" alignItems="flex-start">
                      <Styles.ChampionshipName>
                        {match.championship}
                      </Styles.ChampionshipName>
                      <Styles.MatchLocation>
                        {match.timeText} - Estádio {match.stadium}
                      </Styles.MatchLocation>
                    </FlexDiv>
                  </FlexDiv>
                )}
              />
            </FlexDiv>
            <ConditionalRenderer
              condition={isDesktop && match.review}
              renderOnSuccess={() => (
                <FlexDiv
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  flexGrow="1"
                  paddingRight="10px"
                >
                  <Styles.MatchReview to={`noticias/${match.review.slug}`}>
                    Veja como foi
                  </Styles.MatchReview>
                </FlexDiv>
              )}
            />
          </FlexDiv>
        </Styles.ItemContainer>
      ))}
    </Styles.Container>
  );
};

export default Matches;
