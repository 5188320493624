import React from 'react';
import FlexDiv from '../flex-div';
import Container from '../container';
import * as Styles from './styles';
import soundcloudIcon from '../../images/soundcloud-icon.svg';
import CallToAction from '../call-to-action';

function SoundCloud() {
  return (
    <Styles.SoundCloudSection>
      <Container
        as={FlexDiv}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <FlexDiv justifyContent="center" width="100%" marginTop="auto">
          <Styles.Image
            src={soundcloudIcon}
            alt="Soundcloud logo"
            aria-describedby="soundcloud-description"
          />
          <FlexDiv>
            <FlexDiv color="white">
              <Styles.MainParagraph>
                <span>ouça agora</span>
                <span>
                  Soundcloud
                  <br />
                  colorado
                </span>
              </Styles.MainParagraph>
            </FlexDiv>
          </FlexDiv>
        </FlexDiv>
        <FlexDiv
          justifyContent="center"
          width="100%"
          alignItems="center"
          margin="0.5rem 0 auto 0"
        >
          <p
            id="soundcloud-description"
            style={{ margin: 0, marginRight: '1rem' }}
          >
            Ouça entrevistas, hinos e muito mais.
          </p>
          <CallToAction
            to="https://soundcloud.com/scinternacional"
            label="Acesse"
            ariaLabel="Acesse soundcloud colorado"
          />
        </FlexDiv>
      </Container>
    </Styles.SoundCloudSection>
  );
}

export default SoundCloud;
